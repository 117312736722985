<template>
  <el-menu
    active-text-color="#ffd04b"
    background-color="#545c64"
    class="el-menu-vertical-demo"
    :default-active="route.path === '/' ? route.path : route.path.substring(1)"
    router
    text-color="#fff"
    @open="handleOpen"
    @close="handleClose"
  >
    <el-menu-item index="community">
      <el-icon><HomeFilled /></el-icon>
      <span>小区管理</span>
    </el-menu-item>
    <el-menu-item index="station">
      <el-icon><MessageBox /></el-icon>
      <span>站点管理</span>
    </el-menu-item>

    <el-menu-item index="user">
      <el-icon><UserFilled /></el-icon>
      <span>用户管理</span>
    </el-menu-item>
    <!-- <el-menu-item index="map">
        <el-icon><UserFilled /></el-icon>
        <span>地图</span>
      </el-menu-item> -->
  </el-menu>
</template>

<script setup>
import { MessageBox, HomeFilled, UserFilled } from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
const route=useRoute()
const handleOpen = () => {
  console.log();
};
const handleClose = () => {
  console.log();
};

</script>
