import request from "@/utils/request";

// 上传oss
// export function uploadFile(file) {
//   let fd = new FormData();
//   fd.append("file", file);
//   fd.append("subName", "test");
//   // 如果上传的是一个fileList，那自己加个判断再遍历 append一下就行
//   return request({
//     url: "/oss/putObject",
//     method: "post",
//     data: fd,
//   });
// }
// 登录
export const login = (data) => {
  return request({
    url: "/admin/employee/login",
    method: "post",
    data: data,
  });
};

// 获取站点列表接口
export const getStaionList = (data) => {
  return request({
    url: `/admin/station/getStaionList`,
    method: "get",
    params: data,
  });
};
// 通过id获取站点详情
export const getStationDetail = (data) => {
  return request({
    url: `/admin/station/getStationDetail`,
    method: "get",
    params: data,
  });
};
// 通过id修改站点内容
export const updateStationById = (data) => {
  return request({
    url: `/admin/station/updateStationById`,
    method: "put",
    data: data,
  });
};
// 修改站点状态
export const updataStationStatus = (data) => {
  return request({
    url: `/admin/station/updataStationStatus`,
    method: "get",
    params: data,
  });
};
// 通过小区id获取站点列表接口
export const getStaionByCommunityId = (data) => {
  return request({
    url: `/admin/station/getStaionByCommunityId/${data.communityId}`,
    method: "get",
  });
};
// 通过id删除站点
export const deleteStationById = (data) => {
  return request({
    url: `/admin/station/deleteStation/${data.id}`,
    method: "delete",
  });
};
// 新增站点
export const addStation = (data) => {
  return request({
    url: `/admin/station/addStation`,
    method: "post",
    data: data,
  });
};
// 获取小区列表接口
export const getCommunityList = (data) => {
  return request({
    url: "/admin/community/getCommunityList",
    method: "get",
    params: data,
  });
};
// 通过ids获取小区列表接口
export const getCommunityListByIds = (data) => {
  return request({
    url: "/admin/community/getCommunityListByIds",
    method: "post",
    data: data,
  });
};
// 新增小区接口
export const addCommunity = (data) => {
  return request({
    url: "/admin/community/addCommunity",
    method: "post",
    data: data,
  });
};

// 删除小区列表接口
export const delCommunityByID = (data) => {
  return request({
    url: `/admin/community/delCommunityByID/${data.id}`,
    method: "delete",
  });
};

// 新增用户接口
export const addUser = (data) => {
  return request({
    url: "/admin/employee/save",
    method: "post",
    data: data,
  });
};
// 获取用户列表接口
export const getUserList = (data) => {
  return request({
    url: "/admin/employee/page",
    method: "get",
    params: data,
  });
};
// 根据id获取用户详情接口
export const getDetailById = (data) => {
  return request({
    url: "/admin/employee/getDetailById",
    method: "get",
    params: data,
  });
};
// 删除用户
export const deleteUserByID = (data) => {
  return request({
    url: `/admin/employee/deleteUserByID/${data.id}`,
    method: "delete",
  });
};
// 修改用户
export const updateEmplyee = (data) => {
  return request({
    url: `/admin/employee/updateEmplyee`,
    method: "put",
    data: data,
  });
};
